// **  Initial State
const initialState = {
  itemDailyStats: { isMounted: false, data: [], isLoading: false },
  revenues: { isMounted: false, data: [], isLoading: false },
  discounts:  {isMounted: false, data:[], isLoading: false},
  orderAppVersion: { isMounted: false, data: [], isLoading: true },
  orderTotals: { isMounted: false, data: [[], []], isLoading: [true, true] },
  itemStocks: { isMounted: false, data: [], isLoading: true },
  modifiersReport: { isMounted: false, data: [], isLoading: true },
}

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPORT_OT_MOUNTED": {
      const newOT = { ...state.orderTotals }
      newOT.isMounted = true
      return { ...state, orderTotals: newOT }
    }
    case "ADD_REPORT_OT_WEEKLY": {
      const newOT = { ...state.orderTotals }
      const data = [...newOT.data]
      data[0] = action.data
      const isLoading = [...newOT.isLoading]
      isLoading[0] = false
      return { ...state, orderTotals: { ...newOT, data, isLoading } }
    }
    case "ADD_REPORT_OT_MONTHLY": {
      const newOT = { ...state.orderTotals }
      const data = [...newOT.data]
      data[1] = action.data
      const isLoading = [...newOT.isLoading]
      isLoading[1] = false
      return { ...state, orderTotals: { ...newOT, data, isLoading } }
    }
    case "SET_REPORT_OT_LOADING": {
      const newOT = { ...state.orderTotals }
      newOT.isLoading = [true, true]
      return { ...state, orderTotals: newOT }
    }

    case "ADD_REPORT_REVENUES": {
      const newData = { ...state.revenues }
      newData.data = action.data
      newData.isLoading = false
      return { ...state, revenues: newData }
    }
    case "SET_REPORT_REVENUES_LOADING": {
      const newData = { ...state.revenues }
      newData.isLoading = action.data
      return { ...state, revenues: newData }
    }
    case "SET_REPORT_REVENUES_MOUNTED": {
      const newData = { ...state.revenues }
      newData.isMounted = true
      return { ...state, revenues: newData }
    }
    case "SET_DISCOUNT_REPORT_MOUNTED": {
      const newData = {...state.discounts}
      newData.isMounted = true
      return {...state, discounts: newData}
    }
    case "SET_DISCOUNT_REPORT_LOADING": {
      const newData = {...state.discounts}
      newData.isLoading = true
      return {...state, discounts: newData}
    }
    case "ADD_DISCOUNT_REPORT": {
      const newData = {...state.discounts}
      newData.data = action.data
      newData.isLoading = false
      return {...state, discounts: newData}
    }
    case "ADD_ITEM_DAILY_STATS": {
      const newData = { ...state.itemDailyStats }
      newData.data = action.data
      newData.isLoading = false
      return { ...state, itemDailyStats: newData }
    }
    case "SET_ITEM_DAILY_STATS_LOADING": {
      const newData = { ...state.itemDailyStats }
      newData.isLoading = true
      return { ...state, itemDailyStats: newData }
    }
    case "SET_ITEM_DAILY_STATS_MOUNTED": {
      const newData = { ...state.itemDailyStats }
      newData.isMounted = true
      return { ...state, itemDailyStats: newData }
    }

    case "SET_REPORT_APP_ORDER_VERSION_MOUNTED": {
      const newData = { ...state.orderAppVersion }
      newData.isMounted = true
      return { ...state, orderAppVersion: newData }
    }

    case "ADD_REPORT_APP_ORDER_VERSION": {
      const newData = { ...state.orderAppVersion }
      newData.data = action.data
      newData.isLoading = false
      return { ...state, orderAppVersion: newData }
    }
    case "SET_REPORT_APP_ORDER_VERSION_LOADING": {
      const newData = { ...state.orderAppVersion }
      newData.isLoading = true
      return { ...state, orderAppVersion: newData }
    }

    case "ADD_REPORT_ITEM_STOCKS": {
      const newItemStocks = { ...state.itemStocks }
      newItemStocks.data = action.data
      newItemStocks.isLoading = false
      return { ...state, itemStocks: newItemStocks }
    }
    case "SET_REPORT_ITEM_STOCKS_LOADING": {
      const newItemStocks = { ...state.itemStocks }
      newItemStocks.isLoading = true
      return { ...state, itemStocks: newItemStocks }
    }
    case "SET_REPORT_ITEM_STOCKS_MOUNTED": {
      const newItemStocks = { ...state.itemStocks }
      newItemStocks.isMounted = true
      return { ...state, itemStocks: newItemStocks }
    }

    case "SET_REPORTS_ACTIVE_TAB": {
      return { ...state, activeTab: action.data }
    }
    default:
      return state
  }
}

export default reportsReducer
